h1, h2, h3, h4, p, li{
	font-family: "ff-meta-web-pro",sans-serif;
}

button, a{
	cursor: pointer;
}

.block--orange button:hover{
	/*background-color: #214683;*/
	background-color: white;
	color: #ea4d2e;
	cursor: pointer;
}

.block--blue button:hover{
	/*background-color: #ea4d2e;*/
	background-color: white;
	color: #214683;
	cursor: pointer;
}

#overlay {
  position:fixed;
  top:0;
  left:0;
  z-index:1;
  height:100%;
  width:100%;
  background:#000000;
  opacity:0.6;
  display:none;    
  button{
  	background-image: url('/images/x.png');
  	width: 3em;
  	height: 3em;
  	position: fixed;
  	top: 5px;
  	left: 10px;
  	filter: contrast(160%);
  	background-size: cover;
  	background-color: transparent;
  	border: 0px;
  }
}

#video {
	display:none;
	position: absolute;
	top:10%;
	left: 50%;
	z-index:2;
	width: 100%;
	height: 75%;
	transform: translateX(-50%);
}

#video2 {
	display:none;
	position: fixed;
	top:3em;
	left: 50%;
	z-index:2;
	width: 100%;
	height: 75%;
	transform: translateX(-50%);
}


.hero{
	background-image: url('/images/hero.png');
	background-size: cover;
	width: 100%;
	height: 100vh;
	background-repeat: no-repeat;
	text-align: center;
	.logo{
		padding-top: 5%;
		width: 25%;
	}
	.play{
		width: 8%;
	}
	h1{
		color: white;
		text-align: center;
		font-size: 48px;
		padding-top: 12vh;
		margin-left: 25%;
		margin-right: 25%;
	}
	.play-button{
		background-image: url('/images/play.svg');
		background-size: contain;
		border: 0px;
		width: 6em;
		height: 6em;
		transition: 0.6s ease;
		cursor: pointer;
	}
}

.play-button:hover{
	transform: scale(1.05);
}

.profile{
	text-align: center;
  border-radius: 50%;
  width: 6em;
  margin-top: 1em;
}

.flip{
	-moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.trey{
	background-image: url('/images/Trey-Phillips.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
}

.charles{
	background-image: url('/images/Charles-Lamb.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left;
}

.tabitha{
	background-image: url('/images/Tabitha-Hayes.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right;	
}

.nick{
	background-image: url('/images/Nick-Reed.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right;
}

.block{
	width: 100%;
	lost-column: 1/1 0;
	p{
		color: black;
		text-align: left;
		line-height: 1.6em;
		font-size: 14px;
	}
	h2{
		text-align: center;
		color: black;
		margin-top: 0px;
		padding-top: .5em;
	}
	&__stat{
		padding-top: 5em;
		div{
			text-align: center;
		}
	}
	&__text{
		padding-top: 4em;
    padding-bottom: 4em;
		display: block;
		div{
			text-align: center;
		}
	}
	button{
		margin-bottom: .5em;
		margin-top: 1em;
		background-color: transparent;
		border: 3px solid white;
		padding: .5em 2em;
		color: white;
		transition: background-color 0.6s ease;
	}
	&--blue, &--orange{
		min-height: 28em;
		p{
			color: white;			
			margin-bottom: 0px;
			padding-bottom: .5em;
		}
		h2{
			color: white;
		}
	}
	&--blue{
		background-color: #214683;
	}
	&--gray-white{
		/*background: linear-gradient(#ffffff 50%, #f2f2f2 50%);*/
		background-size: 50%;
		background-color: #f2f2f2;
	}
	&--orange{
		background-color: #ea4d2e;
	}
	&--white{
		background-image: url("/images/powerlines.jpg");
		background-repeat: no-repeat;
		position: relative;
		min-height: 14em;
		background-position: 100% 0%;
		background-size: cover;
	}
}

.stat-box{	
	text-align: center;
	width: 200px;
	height: 250px;
	&__left{
		background-color: #ea4d2e;
		margin-left: 7em;
		float: right;
		margin-right: 4em;
	}
	&__right{
		background-color: #214683;
		margin-left: 4em;
	}
	h3{
		color: white;
		font-size: 38px;
		margin: 0px;
	}
	p{
		text-align: center;
		padding: 0px 10px;
	}
}

.stat-icon{
	margin-top: 1em;
	fill: white;
	display: inline-block;
	background-repeat: no-repeat;
	background-size: contain;
	width: 5em;
	height: 6em;
	&__powerline{	
		margin-top: 1em;
		background-image: url('/images/powerline.png');	
	}
	&__lightbulb{	
		width: 4em;
		background-image: url('/images/lightbulb.png');	
	}
	&__money{	
		margin-top: 1em;
		width: 8em;		
		background-image: url('/images/money.png');	
	}
	&__oklahoma{
		margin-top: 2em;		
		width: 8em;
		height: 5em;	
		background-image: url('/images/oklahoma.png');	
	}
}

.el-illustration{
	background-color: white;
	margin-left: auto;
	margin-right: auto;
	display: block;
	margin-bottom: 5.5em;
	clear: both;
	width: 80%;
	display: flex;
	align-items:center;
	ul{
		display: inline;
		li{
			text-indent:-30px; 
			margin-left:30px;
			margin-right: 0px;
			list-style: none;
			font-size: 14px; 
			line-height: 1.5em;
		}
	}
	img{
		display: inline-block;
	}
}

.el-li{
	margin-left: 2em;
	margin-right: 2em;
	background-color: white;	
	vertical-align: top;
	img{
		transform: translateY(34%);
		display: inline-block;
		width: 2em;
	}
}

.ill{
	width: 66%;
}

@media(max-width: 1140px){
	.el-illustration{
		ul li{
			text-indent: -27px;
			font-size: 13px;
			line-height: 1.25em;
		}
	}
	.el-li{
		margin-left: 1em;
	}
	.block--white{
		min-height: 14em;
	}
}

@media(max-width: 985px){
	.el-illustration{
		width: 86%;
		margin-left: ;
	}
	.el-li{
		margin-left: 0em;
		margin-right: 1em;
	}
}

.el-right{
	display: inline-block;
	width: 64%;	
}

.three-types{
	padding-top: 1em;
	position: relative;
	padding-left: 15%;
  padding-right: 15%;
  text-align: center;
  h2{
  	padding: 2em 0em;
  	margin-bottom: 0px;
  }
  &__box{
		box-shadow: 0px 2px 2px 2px #cdcdcd;
		background-color: white;
		max-height: 23em;
		min-height: 23em;
  }
	h4{
		color: #000000;
		padding: 1.25em 1em;
		background-color: #cdcdcd; 
		margin-top: 0px;
		margin-bottom: 0px;
	}
	ul{
		padding-left: 0px;
		li{
			line-height: 2em;
			list-style: none;
			text-align: center;
			color: black;
		}
		span{
			font-weight: bold;
			padding: 5px 0px;
		}
	}
	ul li:nth-child(even){
		line-height: 1em;
		padding-bottom: 1em;
	}
}

@media(max-width: 910px){
	.three-types{
		padding-left: 5%;
		padding-right: 5%;
		li{
			font-size: 15px;
			padding: 0px 3px;
		}
	}
}

@media(min-width: 1550px){
	.el-illustration{
		width: 70%;
		margin-left: 15%;
		margin-right: 15%;
	}
	.three-types{
		padding-left: 25%;
		padding-right: 25%;
	}
}

.find-your{
	padding-bottom: 0em;
	padding-top: 3em;
	h2{
		text-align: center;
	}
}

.orange{
	color: white!important;
	background-color: #ea4d2e!important;
}

.video{
	background-image: url('http://via.placeholder.com/1100x800');
	background-size: cover;
  height: 400px;
}

.pp-orange{
	color: white;
}

.pp-orange:visited{
	color: white;
}

footer{
	float: right;
	height: 6em;
	background-color: white;
	padding-right: 5em;
	padding-top: 1.2em;
	.pp{
		color: #cdcdcd;
		font-size: 14px;
		text-decoration: none;
	}
	.pp:visited{
		color: #cdcdcd;
	}
	.fb{
		border: 0px;
		padding: 15px;
		text-decoration: none;
		cursor: pointer;
		.facebook{
			width: 1.2em;
			height: 1.3em;
			transform: translateY(5px);
			cursor: pointer;
		}
		.facebook:hover{
			opacity: .8;
		}
		.twitter{
			width: 1.5em;
			height: 1.2em;
		}
	}
	.community{
		background-color: transparent;
		border: 2.5px solid #cdcdcd;
		padding: .75em 1.5em;
		font-size: 14px;
		color: #cdcdcd;
		cursor: pointer;
		transition: 0.6s ease;
		text-decoration: none;
	}
	.community:hover{
		background-color: #cdcdcd;
		color: white;
	}
}

.lost-left{
	lost-column: 6.5/22 0;
}

.lost-right{
	lost-column: 6.5/22 0;
}

.lost-middle{
	lost-column: 9/22 0;
}

.lost-center{
	lost-column: 9/22 0;
}

.lost-offset{
	lost-offset: 6.5/22 0;
}

.lost-offset-blue{
	lost-offset: 6.5/22 0;
}

.lost-one{
	&__third{
		lost-column: 1/3 0;
		display: inline-block;
	}
}

.lost-two{
  &__fifths{
	lost-column: 2/5 0;
  }
  &__thirds{
    lost-column: 2/3 0;
  }
}

.lost-three{
  &__fourths{
    lost-column: 3/4 0;
  }
 }


@media(max-width: 1100px){
	.three-types{
		padding-left: 10%;
		padding-right: 10%;
		ul li{
			line-height: 1.9em;
		}
	}
  .lost-center{
  	lost-column: 10/22 0;
  }
  .lost-offset{
  	lost-offset: 6/22 0;
  }
  .stat-box{
  	&__left{
  		margin-left: 4em;
  	}
  	&__right{
  		margin-left: 2em;
  	}
  }
}

@media(max-width: 950px){
	.hero{
		background-position: center;
		.logo{
			padding-top: 15%;
		}
		h1{
			font-size: 40px;
		}
	}
  .lost-center{
  	lost-column: 14/22 0;
  	h2{
  		/*text-align: left;*/
  	}
  }
  .lost-offset{
  	lost-offset: 4/22 0;
  }
  .stat-box{
  	&__left{
  		margin-left: 2em;
  		float: none;
  	}
  	&__right{
  		margin-left: 0em;
  	}
  }
  .block--orange, .block--blue{
  	background-image: none;
  }
  .block--orange{
		align-items: flex-start;
		display: flex;
		order: 1;
		flex-direction: column;		
	}
	.block--orange .block__stat{
		width: 100%;
		.stat-box__right{
			margin-left: auto;
			margin-right: auto;
		}
	}
	.lost-offset-blue{
		display: flex;
		flex-direction: column;
		order: 2;
		width: 100%;
	}
	.lost-middle{
		lost-column: 4/4;
		padding-left: 10%;
		padding-right: 10%;
	}
	.lost-left{
		lost-column: 4/4 0;
	}
	.stat-box{
  	&__left{
  		margin-left: auto;
    	margin-right: auto;
  	}
  }
  .lost-offset-blue{
  	lost-offset: 0/4;
  }
  .block--white{
  	min-height: 14em;
  }
}

@media(max-width: 850px){
  .block__text{
  	padding-top: 2em;
  }
  .three-types{
  	padding-left: 1em;
  	padding-right: 1em;
  	&__box{
  		margin-left: auto;
  		margin-right: auto;
  		min-height: 23em;
  		max-height: 23em;
  	}
  	ul{
  		/*margin-top: 0px;*/
  		li{
  			line-height: 2em;
  		}
  	}
  }
  .el-illustration{
		width: 86%;
		display: flex;
		flex-direction: column;
		order: 1;
	}
	.el-li{
		display: flex;
		flex-direction: column;
		order: 2;
		margin-left: 0em;
		margin-right: 0em;
		width: 100%;
		ul{
			padding-left: 10px;
		}
	}
	.ill{
		width: 100%;
	}
	.find-your p{
		margin-left: 4em;
		margin-right: 4em;
	}
  footer{
		padding-right: 1em;
	}
}

@media(max-width: 800px){
	.hero{
		.logo{
			padding-top: 20%;
			width: 35%;
		}
		h1{
			font-size: 34px;
			margin-left: 10%;
			margin-right: 10%;
		}
	}
  .three-types__box{
  	/*min-height: auto;*/
  	/*max-height: auto;*/
  	ul li{
  		padding-left: 4px;
  		padding-right: 4px;
  		line-height: 1.6em;
  	}
	}
}

@media(max-width: 750px){
  .lost-center{
  	lost-column: 18/22 0;
  }
  .lost-offset{
  	lost-offset: 2/22 0;
  }
  .block--white{
  	background-size: cover;
  }
  .find-your p{
		margin-left: 2em;
		margin-right: 2em;
	}
	.block--white{
		background-image: none;
	}
}

@media(max-width: 650px){
	.hero{
		.logo{
			width: 45%;
		}
		.play-button{
			width: 5em;
			height: 5em;
		}
	}
  .three-types__box{
  	position: relative;
  	lost-column: 1/2 0!important;
  	margin-left: 24%;
  	margin-right: 24%;
  	ul li{
  		line-height: 2em;
  	}
	}
	.accordion{
		cursor: pointer;
		margin-top: 2em;
		height: auto;
		min-height: auto;
	}
	.accordion ul{
		display: none;
	}
	.accordion__open {
		min-height: auto;
	}
	.accordion__open ul{
	  display: block!important;
	  padding-right: 0px;
	  max-height: 1500px!important;
	  transition: max-height 0.5s ease-in;
	}
}

@media(max-width: 550px){
	.hero{
		.logo{
			padding-top: 30%;
			width: 45%;
		}
		h1{
			font-size: 30px;
		}
	}
	.block--white{
		position: relative;
		/*min-height: 44em;*/
	}
  .three-types__box{
  	lost-column: 4/5 0!important;
  	margin-left: 10%;
  	margin-right: 10%;
  	ul li{
  		line-height: 2.2em;
  	}
	}
	.find-your p{
		margin-left: 0em;
		margin-right: 0em;
	}
}