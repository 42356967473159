.skip_nav{
	display:block;
  background-color:black;
  color:white!important;
  display:block;
  position:fixed;
  z-index:99999;
  padding:.5em .75em;
  text-transform:uppercase;
  top:0;
  left:-999em;
  &:focus {
    left:0;
  }
}

.none{
  display: none;
}